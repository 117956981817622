<template>
	<ShutterPanel />
</template>


<script type="text/javascript">
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
    import Shutter from '@/mixins/Shutter.js'
    import ShutterTrigger from '@/mixins/shutters-manager/Triggers.js'

    export default {
		name: "BonShutter",
		mixins: [Shutter, ShutterTrigger],
		props: {
			items: { type: Array, default: () => [] },
			table_busy: { type: Boolean, default: false }
		},
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ShutterPanel
		},
		mounted() {
			this.init_component()
		},
		methods: {
			init_component() {
				this.setupMonteReservation(this.items)
			},
			submitConfirm(monte_resa_selected) {
				this.mare_trigger = []
				monte_resa_selected.forEach(resa => {
					if(resa.mare && resa.doses > 0) {
						this.mare_trigger.push(resa.mare.horse_id)
					}
				})
				

				for(let i in this.mare_trigger) {
					this.setupActPropositionMonteReservation(this.mare_trigger[i], (result) => {
						result.forEach(triggered => {
	                        this.triggered_list.push({
	                            type: triggered.type,
	                            horses: triggered.horses,
	                            date: triggered.date
	                        })
	                    })

						if(i == this.mare_trigger.length - 1) {
		                    this.shutterPanel().close('proposition-acte')
							this.refreshTable()
		                    this.nextTrigger()
		                }
	                },() => {
						this.refreshTable()
	                })
				}
			},
			nextTrigger() {
				if(this.triggered_list.length > 0) {
					const triggered = this.triggered_list.shift()
					if(triggered.type == "pension_reouverture") {
						this.setupNewTriggered(triggered.horses, new Date(), triggered.type, this.mare_trigger[0], this.triggered_list)
					} 
					else {
						this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.mare_trigger[0], this.triggered_list)
					}
				} else {
					if(this.mare_trigger.length > 1) {
						this.$router.push({ name: 'mouvementListe' })
					}
					else {
						this.$router.push({ name: 'horseFiche', params: {horse_id: this.mare_trigger[0]}})
					}
				}
			},
		}
    }
</script>
